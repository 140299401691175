import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { makeParams } from 'app/modules-core/utility/helpers';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.ROLES = {
            ADMIN: 'ROLE_ADMIN',
            MERCHANDISER: 'ROLE_MERCHANDISER',
            ROLE_MERCHANDISER_MANAGER: 'ROLE_MERCHANDISER_MANAGER',
            ROLE_KAM: 'ROLE_KAM',
        };
        this.urlBase = 'users';
        this.httpOptions = {
            params: new HttpParams()
        };
    }
    UserService.prototype.getAll = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http.get(this.urlBase, {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params
            }, { queryParams: opts }))
        }).pipe(map(function (resp) {
            resp.data = _.map(resp.data, function (v, k) {
                v.fullname = _.get(v, 'firstName') + " " + _.get(v, 'lastName');
                return v;
            });
            return resp.data;
        }));
    };
    UserService.prototype.getAllV2 = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http.get(this.urlBase + "/light", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params
            }, { queryParams: opts }))
        }).pipe(map(function (resp) {
            resp.data = _.map(resp.data, function (v, k) {
                v.fullname = _.get(v, 'firstName') + " " + _.get(v, 'lastName');
                return v;
            });
            return resp.data;
        }));
    };
    UserService.prototype.get = function (id) {
        return this.http.get(this.urlBase + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.create = function (payload) {
        return this.http.post(this.urlBase, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.update = function (id, payload) {
        return this.http.patch(this.urlBase + "/" + id, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.updatePassword = function (payload) {
        return this.http.put(this.urlBase + "/resetpassword", payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.updateFields = function (id, payload) {
        return this.http.patch(this.urlBase + "/" + id, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.delete = function (id) {
        return this.http.delete(this.urlBase + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.createUser = function (data) {
        var payload = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            roles: [{
                    id: 1 //todo, remove hardcode
                }],
            factoryId: "2" //todo - remove
        };
        return this.http.post(this.urlBase, payload).pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.getUser = function (id) {
        return this.http.get(this.urlBase + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.createEmployeeUser = function (data) {
        return this.http.post(this.urlBase, data).pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.updateEmployeeUser = function (data, id) {
        return this.http.patch(this.urlBase + "/" + id, data).pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.assignRole = function (id, payload) {
        return this.http.post(this.urlBase + "/" + id + "/roles", payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.logout = function () {
        return this.http.post(this.urlBase + "/logout", {}).pipe(map(function (resp) { return resp.data; }));
    };
    UserService.prototype.resetpass = function (payload) {
        return this.http.put(this.urlBase + "/resetpassword", payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}()); //class
export { UserService };
