export const LOCAL_STORAGE_TOKEN = 'itcEpcCcmisAccessToken';
export const LOCAL_STORAGE_USER = 'itcEpcCcmisUser';
export const LOCAL_STORAGE_REDIRECT = 'itcEpcCcmisRedirectUrl';
export const LOCAL_URL = 'https://10.10.14.246';
export const LOCAL_URL_HTTP = 'http://10.10.14.246';
export const PUBLIC_URL = 'http://103.218.164.17';
export const paginationListItemPerPage = getItemNumber();

function getItemNumber() {
  const items = 50;
  return items;
}