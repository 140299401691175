import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { makeParams } from 'app/modules-core/utility/helpers';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  ROLES = {
    ADMIN: 'ROLE_ADMIN',
    MERCHANDISER: 'ROLE_MERCHANDISER',
    ROLE_MERCHANDISER_MANAGER: 'ROLE_MERCHANDISER_MANAGER',
    ROLE_KAM: 'ROLE_KAM',
  }

  urlBase = 'users';
  httpOptions = {
    params: new HttpParams()
  }

  constructor(
    private http: HttpClient,
  ) {
  }

  getAll(opts = {}) {
    return this.http.get(this.urlBase, {
      params: makeParams(_.merge({
        httpParams: this.httpOptions.params
      }, { queryParams: opts }))
    }).pipe(map((resp: any) => {
      resp.data = _.map(resp.data, (v, k) => {
        v.fullname = _.get(v, 'firstName') + " " + _.get(v, 'lastName');
        return v;
      });
      return resp.data;
    }));
  }

  getAllV2(opts = {}) {
    return this.http.get(`${this.urlBase}/light`, {
      params: makeParams(_.merge({
        httpParams: this.httpOptions.params
      }, { queryParams: opts }))
    }).pipe(map((resp: any) => {
      resp.data = _.map(resp.data, (v, k) => {
        v.fullname = _.get(v, 'firstName') + " " + _.get(v, 'lastName');
        return v;
      });
      return resp.data;
    }));
  }

  get(id) {
    return this.http.get(`${this.urlBase}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  create(payload) {
    return this.http.post(this.urlBase, payload)
      .pipe(map((resp: any) => resp.data));
  }

  update(id, payload) {
    return this.http.patch(`${this.urlBase}/${id}`, payload)
      .pipe(map((resp: any) => resp.data));
  }
  updatePassword(payload) {
    return this.http.put(`${this.urlBase}/resetpassword`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  updateFields(id, payload) {
    return this.http.patch(`${this.urlBase}/${id}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  delete(id) {
    return this.http.delete(`${this.urlBase}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  createUser(data) {
    let payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      roles: [{
        id: 1 //todo, remove hardcode
      }],
      factoryId: "2" //todo - remove
    }

    return this.http.post(
      this.urlBase,
      payload
    ).pipe(map((resp: any) => resp.data));
  }

  getUser(id) {
    return this.http.get(`${this.urlBase}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }


  createEmployeeUser(data) {
    return this.http.post(
      this.urlBase,
      data
    ).pipe(map((resp: any) => resp.data));
  }
  updateEmployeeUser(data, id) {
    return this.http.patch(
      `${this.urlBase}/${id}`,
      data
    ).pipe(map((resp: any) => resp.data));
  }
  assignRole(id, payload) {
    return this.http.post(`${this.urlBase}/${id}/roles`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  logout() {
    return this.http.post(
      `${this.urlBase}/logout`,
      {}
    ).pipe(map((resp: any) => resp.data));
  }

  resetpass(payload) {
    return this.http.put(`${this.urlBase}/resetpassword`, payload)
      .pipe(map((resp: any) => resp.data));
  }
} //class


