import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";
import { makeParams } from "app/modules-core/utility/helpers";
import * as _ from "lodash";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContractService = /** @class */ (function () {
    function ContractService(http) {
        this.http = http;
        this.urlBase = "contracts";
        this.urlBaseContractProductCategory = "contract-product-categories";
        this.urlBaseContractProduct = "contract-products";
        this.urlBaseContractLots = "contract-lots";
        this.httpOptions = {
            params: new HttpParams(),
        };
    }
    ContractService.prototype.getAll = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBase + "/v2", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) {
            resp.data = _.map(resp.data, function (contract) {
                contract['advance'] = _.chain(contract.contractPaymentTerms).find(['paymentTypeName', 'advance']).value();
                contract['progressive'] = _.chain(contract.contractPaymentTerms).find(['paymentTypeName', 'progressive']).value();
                contract['retention'] = _.chain(contract.contractPaymentTerms).filter(['paymentTypeName', 'retention']).value();
                contract['retentionFirst'] = _.head(contract['retention']);
                contract.retention.splice(0, 1);
                return contract;
            });
            return resp;
        }));
    };
    ContractService.prototype.getContractsLight = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBase + "/light", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) {
            return resp.data;
        }));
    };
    ContractService.prototype.getContractsLots = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBaseContractLots + "/light", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) {
            return resp.data;
        }));
    };
    ContractService.prototype.get = function (id) {
        return this.http
            .get(this.urlBase + "/" + id + "/v2")
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getDeliveryLot = function (id) {
        return this.http
            .get(this.urlBase + "/" + id + "/generate-delivery-lot")
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.create = function (payload) {
        return this.http
            .post(this.urlBase, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.update = function (payload) {
        return this.http
            .put("" + this.urlBase, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.updateFields = function (payload) {
        return this.http
            .patch("" + this.urlBase, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.delete = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http
            .delete(this.urlBase + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    //Contract Product Category
    ContractService.prototype.getAllContractProductCategory = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get("" + this.urlBaseContractProductCategory, {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getContractProductCategory = function (id) {
        return this.http
            .get(this.urlBaseContractProductCategory + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.createContractProductCategory = function (payload) {
        return this.http
            .post(this.urlBaseContractProductCategory, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.updateContractProductCategory = function (payload) {
        return this.http
            .put("" + this.urlBaseContractProductCategory, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.updateContractProductCategoryFields = function (payload) {
        return this.http
            .patch("" + this.urlBaseContractProductCategory, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.deleteContractProductCategory = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http
            .delete(this.urlBaseContractProductCategory + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getClientInfo = function (id, opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBaseContractProductCategory + "/" + id + "/client-info", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getNextLotNo = function (id) {
        return this.http
            .get(this.urlBaseContractProductCategory + "/" + id + "/generate-lot")
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getPlanningNextLotNo = function (id) {
        return this.http
            .get(this.urlBaseContractProductCategory + "/" + id + "/generate-lot?projection=true")
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getQuarterInfo = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBaseContractProductCategory + "/quarter-info", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) { return resp.data; }));
    };
    //Contract Product 
    ContractService.prototype.getAllContractProduct = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get("" + this.urlBaseContractProduct, {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getContractProduct = function (id) {
        return this.http
            .get(this.urlBaseContractProduct + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.createContractProduct = function (payload) {
        return this.http
            .post(this.urlBaseContractProduct, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.updateContractProduct = function (payload) {
        return this.http
            .put("" + this.urlBaseContractProduct, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.updateContractProductFields = function (payload) {
        return this.http
            .patch("" + this.urlBaseContractProduct, payload)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.deleteContractProduct = function (id) {
        return this.http
            .delete(this.urlBaseContractProduct + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getBudgetHistories = function (id) {
        return this.http
            .get(this.urlBase + "/" + id + "/budget-histories")
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getLotHistories = function (id, opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBase + "/" + id + "/lot-histories", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getAllContractLiteVersion = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBase + "/light", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.prototype.getAllBudgetSummary = function (id, opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBase + "/" + id + "/budget-summaries", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) { return resp; }));
    };
    ContractService.prototype.getContractProductCategoryLotHistories = function (id, opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBaseContractProductCategory + "/" + id + "/lots", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) { return resp.data; }));
    };
    ContractService.ngInjectableDef = i0.defineInjectable({ factory: function ContractService_Factory() { return new ContractService(i0.inject(i1.HttpClient)); }, token: ContractService, providedIn: "root" });
    return ContractService;
}());
export { ContractService };
