import * as moment from 'moment';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';


export function toDateString(date) {
  return formatDate(date);
}

export function toTimeString(date) {
  return formatDate(date, 'HH:mm:ss');
}

export function toDateTimeString(date) {
  return formatDate(date, 'YYYY-MM-DD HH:mm:ss');
}

export function toYearString(date) {
  return formatDate(date, 'YYYY');
}

export function niceDateTime(date) {
  return formatDate(date, 'lll');
}

function formatDate(date, format = 'YYYY-MM-DD') {
  return moment(date).format(format)
}

export function percentAmount(firstNumber, secondNumber) {
  return _.divide((_.multiply(firstNumber, secondNumber)), 100);
}

export function Days() {
  return {
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday"
  };
}

export function getDays() {
  return [
    { value: "SATURDAY", label: "Saturday" },
    { value: "SUNDAY", label: "Sunday" },
    { value: "MONDAY", label: "Monday" },
    { value: "TUESDAY", label: "Tuesday" },
    { value: "WEDNESDAY", label: "Wednesday" },
    { value: "THURSDAY", label: "Thursday" },
    { value: "FRIDAY", label: "Friday" },
  ];
}

export function isValidDate(dateString = '') {
  if (dateString) {
    return moment(dateString).isValid();
  }
}

//returns -int if date2 > date1
export function dateDiff(date1, date2) {
  return moment(date1).diff(date2, 'days');
}

export function isDateAfter(date1, date2) {
  if (isValidDate(date1) && isValidDate(date2)) {
    return moment(date1).isAfter(moment(date2));
  }
}

export function isDateBefore(date1, date2) {
  if (isValidDate(date1) && isValidDate(date2)) {
    return moment(date1).isBefore(moment(date2));
  }
}

export function makeParams(opts) {
  let httpParams = !_.isEmpty(opts.httpParams)
    ? opts.httpParams
    : new HttpParams();

  const paginationParamKeys = ['p_page', 'p_size'];
  const sortingParamKeys = ['p_sort', 'p_order'];

  let paginationParams = _.pick(opts.queryParams, paginationParamKeys);
  if (_.isEmpty(paginationParams)) {
    paginationParams = { p_size: 1000000 } //a big number to get all items
  }

  let sortingParams = _.pick(opts.queryParams, sortingParamKeys);
  let otherParams = _.omit(opts.queryParams, _.concat(paginationParamKeys, sortingParamKeys));

  if (!_.isEmpty(sortingParams)) {
    httpParams = httpParams.delete('sort');

    const sortVal = `${sortingParams.p_sort},${sortingParams.p_order}`; //col,asc|desc
    httpParams = httpParams.set('sort', sortVal);
  }

  if (!_.isEmpty(paginationParams)) {
    _.each(paginationParams, (v, k) => {
      const key = _.toLower(_.replace(k, 'p_', ''));
      httpParams = httpParams.delete(key);
      httpParams = httpParams.set(key, _.toString(v));
    })
  }

  if (!_.isEmpty(otherParams)) {
    _.each(otherParams, (v, k) => {
      httpParams = httpParams.delete(_.toLower(k));
      //httpParams = httpParams.set(_.toLower(k), _.toString(v));
      httpParams = httpParams.set(k, _.toString(v));
    })
  }

  return httpParams;
}

export function joinArrayObjectValues(arrayObject, attr = 'id', joinChar = ', ') {
  return _.join(_.map(arrayObject, attr), ', ');
}

export function toQueryParam(obj, prepend = true) {
  let queryString = '';

  queryString = _.map(_.pickBy(obj), function (v, k) { //_.pickBy to only include truthy key vals
    return k + '=' + v;
  }).join('&');

  if (queryString && prepend) {
    queryString = '?' + queryString;
  }

  return queryString;
}

export function stringToIntegerArray(str) {
  var items = [];
  items = _.split(str, ',');
  items = _.map(items, v => _.parseInt(v));
  return items;
}

export function integerStringToInteger(str) {
  if (/^[0-9]*$/.test(str)) {
    str = Number(str);
    return str;
  }
  return str;
}

export function integerArrayToString(str) {
  return _.join(str, ',');;
}

export function removeEmptyFields(obj) {
  for (var key in obj) {
    if (_.isArray(obj[key]) && _.isEmpty(obj[key])) {
      delete obj[key]
    } else if (!obj[key]) {
      delete obj[key]
    }
  }
  return obj;
}

export function getSummarnoteConfig() {
  let config = {
    placeholder: '',
    lineheight: 1,
    tabsize: 1,
    height: 450,
    uploadImagePath: '',
    toolbar: [
      // [groupName, [list of button]]
      ['misc', ['codeview', 'undo', 'redo']],
      // ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }

  return config;
}


export function GraphColor() {
  return ['#396ab1', '#da7c30', '#3e9651', '#cc2529', '#535154', '#a37182', '#922428', '#6b4c9a', '#948b3d', '#eeba69'];
}

export function customSortBy(collection, { field = 'id', sortType, fieldType = 'text' }) {
  if (fieldType == 'text') {
    if (sortType == 'asc') {
      return _.sortBy(collection, field);
    } else {
      return _.sortBy(collection, field).reverse();
    }
  } else if (fieldType == 'number') {
    if (sortType == 'asc') {
      return _.sortBy(collection, field);
    } else {
      return _.sortBy(collection, field).reverse();
    }
  } else if (fieldType == 'date') {
    if (sortType == 'asc') {
      return _.sortBy(collection, field);
    } else {
      return _.sortBy(collection, field).reverse();
    }
  } else {
    return collection;
  }
}

export function quarterlyPeriod() {
  return [
    { id: 1, name: '1st Quarter', firstMonth: '07', lastMonth: '09' },
    { id: 2, name: '2nd Quarter', firstMonth: '10', lastMonth: '12' },
    { id: 3, name: '3rd Quarter', firstMonth: '01', lastMonth: '03' },
    { id: 4, name: '4th Quarter', firstMonth: '04', lastMonth: '06' },
  ];
}

export function getYear() {
  return [
    { value: 2020, label: "2020" },
    { value: "SUNDAY", label: "2021" },
    { value: "MONDAY", label: "2022" },
    { value: "TUESDAY", label: "2023" },
    { value: "WEDNESDAY", label: "2024" },
    { value: "THURSDAY", label: "2025" },
    { value: "FRIDAY", label: "2026" },
  ];
}

export function quarterStartByAnyDate(date) {
  let momentDate = moment(date);
  let quarter = Math.floor((momentDate.month() / 3));
  console.log()

  return formatDate(moment(`${quarter * 3 + 1}-01-${momentDate.year()}`));
}

export function quarterEndByAnyDate(date) {
  let momentDate = moment(date);
  let quarter = Math.ceil((momentDate.month() / 3));
  let finalDate = toDateString(moment(`${quarter * 3}-01-${momentDate.year()}`));

  return formatDate(moment(finalDate).endOf('months'));
}

export function yearToDate(year) {
  let params = {
    startdate: null,
    enddate: null
  };
  params.startdate = year + '-' + '07' + '-' + '01';
  params.enddate = _.add(_.toInteger(year), 1) + '-' + '06' + '-' + '30';
  return params;
}




