import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
  {
    id: "general",
    title: "App",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "dashboard1.png",
        url: "dashboard",
      },
      {
        id: "planning",
        title: "Planning",
        // translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "planning-macro-planning.png",
        url: "planning",
      },

      {
        id: "contracts",
        title: "Contract",
        image: "order.png",
        type: "collapsable",
        permission: "PERMISSION_CONTRACT_VIEW",
        children: [
          {
            id: "upcoming-contracts",
            title: "Upcoming Contract",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "upcoming-contracts",
          },
          {
            id: "planned-contracts",
            title: "Planned Contract",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "planned-contracts",
          },
          {
            id: "inexecution-contracts",
            title: "Inexecution Contract",
            type: "item",
            icon: "dashboard",
            image: "in execution contract.png",
            url: "inexecution-contracts",
          },
          {
            id: "closed-contracts",
            title: "Closed Contract",
            type: "item",
            icon: "dashboard",
            image: "in execution contract.png",
            url: "closed-contracts",
          },

        ]
      },
      {
        id: "budgets-for-approval",
        title: "Budgets For Approval",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "dashboard.png",
        url: "budgets-for-approval"
      },
      {
        id: "delivery-lot",
        title: "Delivery Lots",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "dashboard.png",
        url: "delivery-lots",
        permission: "PERMISSION_CONTRACT_LOT_VIEW",
      },
      {
        id: "psi",
        title: "PSI",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "dashboard.png",
        url: "psis",
        permission: "PERMISSION_PSI_VIEW",
      },
      {
        id: "delivery",
        title: "Delivery",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "delivery.png",
        url: "delivery",
        permission: "PERMISSION_DELIVERY_VIEW"
      },

      {
        id: "pli",
        title: "PLI",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "dashboard.png",
        url: "pli",
        permission: "PERMISSION_PLI_VIEW"
      },
      {
        id: "invoices",
        title: "Invoice",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "invoice.png",
        url: "invoices",
        permission: "PERMISSION_INVOICE_VIEW"
      },
      {
        id: "payments",
        title: "Payments",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        image: "payment.png",
        url: "payments",
        permission: "PERMISSION_INVOICE_PAYMENT_VIEW"
      },
      {
        id: "direct-sales",
        title: "Direct Sale",
        image: "direct sales.png",
        type: "collapsable",
        permission: "PERMISSION_DIRECT_SALE_VIEW",
        children: [
          {
            id: "Direct Sale order",
            title: "Order",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "direct-sales",
          },
          {
            id: "direct-sale-varification",
            title: "Varify",
            translate: "Varify",
            type: "item",
            icon: "varify",
            image: "dashboard.png",
            url: "direct-sale-varification",
          },
          {
            id: "direct-sale-delivery",
            title: "Delivery",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "direct-sale-delivery",
          },
          {
            id: "direct-sale-payments",
            title: "Payment",
            translate: "Payment",
            type: "item",
            icon: "Payment",
            image: "dashboard.png",
            url: "direct-sale-payments",
          },
          {
            id: "direct-sale-init-order",
            title: "Old Order",
            translate: "Old Order",
            type: "item",
            icon: "Payment",
            image: "dashboard.png",
            url: "direct-sale-init-order",
          },

          {
            id: "direct-sale-report",
            title: "Report",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "direct-sale-report",
          },
          {
            id: "email-notifications",
            title: "Email Notifications",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "email-notifications",
          },

        ]


      },
      {
        id: "reports",
        title: "Report",
        image: "order.png",
        type: "collapsable",
        children: [
          {
            id: "plan-target-report",
            title: "Plan Target",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "plan-target-report",
            permission: "PERMISSION_REPORT_PLAN_TARGET_VIEW"
          },
          {
            id: "credit-summary-report",
            title: "Credit Summary",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "credit-summary-report",
            permission: "PERMISSION_REPORT_CREDIT_SUMMARY_VIEW"
          },
          {
            id: "credit-details-report",
            title: "Credit Details",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "credit-details-report",
            permission: "PERMISSION_REPORT_CREDIT_DETAIL_VIEW"
          },
          {
            id: "cost-budget-cumulative-report",
            title: "Cumulative Cost Budget",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "cost-budget-cumulative-report",
            permission: "PERMISSION_REPORT_CUMULATIVE_COST_BUDGET_VIEW"
          },
          {
            id: "gross-profit-selling-price-report",
            title: "Gross Profit & Selling",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "gross-profit-selling-price-report",
            permission: "PERMISSION_REPORT_GROSS_PROFIT_VIEW"
          },
          {
            id: "budgetary-costing-valuation-report",
            title: "Budgetary Costing Valuation",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "budgetary-costing-valuation-report",
            permission: "PERMISSION_BUDGETARY_COSTING_VALUATION_VIEW"
          },
          {
            id: "budgetary-costing-valuation-details-report",
            title: "Budgetary Costing Valuation Details",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "budgetary-costing-valuation-details-report",
            permission: "PERMISSION_BUDGETARY_COSTING_VALUATION_DETAIL_VIEW"
          },
          {
            id: "forecasted-actual-consumed-report",
            title: "Forecasted vs Actual Consumed",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "forecasted-actual-consumed-report",
          },
          {
            id: "payment-assumptions",
            title: "Payment Assumptions",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "payment-assumptions",
            permission: "PERMISSION_PAYMENT_ASSUMPTION_VIEW"
          },
          {
            id: "collection-report",
            title: "Collections",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "collection-report",
          },
          {
            id: "delivery-collection-report",
            title: "Delivery & Collections",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "delivery-collection-report",
          },
          {
            id: "contracts-report",
            title: "Contracts",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "contracts-report",
          },
          {
            id: "contract-product-report",
            title: "Contract Products",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "contract-product-report",
          },

        ]
      },
      {
        id: "forecast-reports",
        title: "Forecast Report",
        image: "order.png",
        type: "collapsable",
        children: [
          {
            id: "occupancy-capacity-report",
            title: "Occupancy vs Capacity Report",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "occupancy-capacity-report",
          },
          {
            id: "quarterly-revenue-report",
            title: "Quarterly Revenue Report",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "quarterly-revenue-report",
          },
          {
            id: "contract-status-report",
            title: "Contract Status Report",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "contract-status-report",
          },
          {
            id: "contract-acquisition-report",
            title: "Monthly Acquisition Report",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "contract-acquisition-report",
          },
          {
            id: "executing-contract-report",
            title: "Executing Contract Report",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "executing-contract-report",
          },

          {
            id: "variance-report",
            title: "Variance Report",
            type: "item",
            icon: "dashboard",
            image: "dashboard.png",
            url: "variance-report",
          },
        ]
      },
      {
        id: "projects",
        title: "Projects",
        type: "item",
        icon: "settings",
        image: "dashboard.png",
        url: "projects",
        // permission: "PERMISSION_MENU_SETTINGS",
      },
      {
        id: "options",
        title: "Settings",
        type: "item",
        icon: "settings",
        image: "dashboard.png",
        url: "options",
        // permission: "PERMISSION_MENU_SETTINGS",
      },


    ]
  },

];
