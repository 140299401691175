<mat-toolbar class="p-0 mat-elevation-z1">

  <!-- watches http calls -->
  <ng-progress [spinner]="false"
    [thick]="true"></ng-progress>

  <div fxFlex
    fxFill
    fxLayout="row"
    fxLayoutAlign="start center">

    <div fxFlex="1 0 auto"
      fxLayout="row"
      fxLayoutAlign="start center">

      <button mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md></div>


      &nbsp;
      <span>
        <ng-container *ngIf="heading?.backlink">
          <button mat-icon-button
            (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </ng-container>

        <ng-container *ngIf="heading?.title">
          <span class="toolbar-title-header"
            [@animate]="{value:'*',params:{delay:'100ms',x:'-15px'}}">
            {{heading?.title}}
          </span>
        </ng-container>


      </span>

      &nbsp;

      <!-- <div fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon"
            src="assets/images/logos/fuse.svg">
        </div>
      </div> -->

      <!-- <div class="px-8 px-md-16">
        <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
      </div> -->

    </div>

    <div class=""
      fxFlex="0 1 auto"
      fxLayout="row"
      fxLayoutAlign="start center">
      <div fxFlex="235px"
        *ngIf="currentUser?.email == 'admin@itc.com'">
        <button (click)="migrateDB()"
          [disabled]="btnDisabled"
          class="migrate-btn">
          Migrate
        </button>
      </div>
      <div fxFlex="235px">
        <input placeholder="Reference / PO No"
          class="barcode-input-field"
          type="search"
          (keyup.enter)="goToPath()"
          [(ngModel)]="scanedItem">
        <button class="barcode-btn"
          (click)="goToPath()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div fxFlex="50px"></div>

      <!-- <button mat-icon-button
        [routerLink]="[{ outlets: { modal: ['create'] } }]">
        <mat-icon class="s-24"
          title="Create a new entity">add_circle_outline</mat-icon>
      </button> -->

      <div class="toolbar-separator"></div>

      <button mat-button
        [matMenuTriggerFor]="userMenu"
        class="user-button">
        <div fxLayout="row"
          fxLayoutAlign="center center">
          <!-- <img class="avatar mr-0 mr-sm-16"
            src="assets/images/avatars/profile.jpg"> -->
          <span class="username mr-12"
            fxHide
            fxShow.gt-sm>{{ currentUser.firstName }}
            {{ currentUser?.lastName }}</span>
          <mat-icon class="s-16"
            fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu"
        [overlapTrigger]="false">

        <button mat-menu-item
          (click)="profile()">
          <mat-icon>account_circle</mat-icon>
          <span>My Profile</span>
        </button>

        <!-- <button mat-menu-item
          class="">
          <mat-icon>mail</mat-icon>
          <span>Inbox</span>
        </button> -->

        <button mat-menu-item
          class="text-red"
          (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>

      </mat-menu>
      <!-- 
      <div class="toolbar-separator"></div>

      <fuse-search-bar (input)="search($event)"></fuse-search-bar>

      <div class="toolbar-separator"></div>

      <button mat-icon-button
        class="quick-panel-toggle-button"
        (click)="goToDiaryPage()"
        aria-label="Go to settings page">
        <mat-icon class="secondary-text">notes</mat-icon>
      </button>

      <button mat-button
        fxHide
        fxShow.gt-xs
        class="language-button"
        [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row"
          fxLayoutAlign="center center">
          <mat-icon class="secondary-text">notes</mat-icon>
        </div>
      </button> -->

      <!-- <mat-menu #languageMenu="matMenu"
        [overlapTrigger]="false">

        <button mat-menu-item
          *ngFor="let lang of languages"
          (click)="setLanguage(lang)">
          <span fxLayout="row"
            fxLayoutAlign="start center">
            <img class="flag mr-16"
              [src]="'assets/icons/flags/'+lang.flag+'.png'">
            <span class="iso"></span>
          </span>
        </button>

      </mat-menu> -->

      <!-- <div class="toolbar-separator"
        fxHide
        fxShow.gt-xs></div>

      <button mat-icon-button
        class="quick-panel-toggle-button"
        (click)="goToSettingsPage()"
        aria-label="Go to settings page">
        <mat-icon class="secondary-text">settings</mat-icon>
      </button> -->
      <!-- 
      <div class="toolbar-separator"
        fxHide
        fxShow.gt-xs></div>

      <button mat-icon-button
        class="quick-panel-toggle-button"
        (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel">
        <mat-icon class="secondary-text">notifications</mat-icon>
        <span class="cbadge"
          *ngIf="countNotification > 0">{{countNotification}}</span>
        <mat-icon matBadge='countNotification'
          matBadgeColor="warn">notifications</mat-icon>
      </button>

      <div class="toolbar-separator"
        *ngIf="!hiddenNavbar && rightNavbar"
        fxHide
        fxShow.gt-xs></div>

      <button mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
   -->
    </div>

  </div>

</mat-toolbar>





<ng-template #contractListModal>
  <h2 mat-dialog-title>Cotract List</h2>
  <mat-dialog-content style="min-height: 75vh;">
    <table class="ctable">
      <thead class="csticky">
        <th>Client</th>
        <th class="text-center">Unit</th>
        <th class="text-center">Contract Type</th>
        <th class="text-center">Contract Ref</th>
        <th class="text-center">Contract PO</th>
        <th>Status</th>
      </thead>

      <tbody *ngFor="let item of contractList">
        <tr (click)="onContract(item)"
          class="cursor-pointer">
          <td>{{item?.clientName}}</td>
          <td>{{item?.companyLabel}}</td>
          <td>{{item?.contractTypeLabel}}</td>
          <td>{{item?.referenceNo}}</td>
          <td>{{item?.contractPo}}</td>
          <td>{{item?.statusLabel}}</td>
        </tr>
      </tbody>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions>
    <btn [text]="'close'"
      [modalClose]="true"></btn>
  </mat-dialog-actions>
</ng-template>