import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class QuickPanelSubjectService {

  private subject = new Subject<any>();

  notification: BehaviorSubject<any> = new BehaviorSubject({});
  notification$: Observable<any> = this.notification.asObservable();

  heading: BehaviorSubject<any> = new BehaviorSubject({});
  heading$: Observable<any> = this.heading.asObservable();

  previousMonthData: BehaviorSubject<boolean> = new BehaviorSubject(false);
  previousMonthData$: Observable<boolean> = this.previousMonthData.asObservable();

  nextMonthData: BehaviorSubject<boolean> = new BehaviorSubject(false);
  nextMonthData$: Observable<boolean> = this.nextMonthData.asObservable();

  // sendQuickPanelData(data) {
  // sendQuickPanelData(data) {
  //   this.notification.next({ data });
  // }

  // getQuickPanelData(): Observable<any> {
  //   return this.notification$.asObservable();
  // }

  // sendHeading(data) {
  //   this.subject.next({ data });
  // }

  // getHeading(): Observable<any> {
  //   return this.subject.asObservable();
  // }
}
