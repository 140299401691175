/**
 * Add this file in
 * Location: www/prod/app.constants.ts
 */
export const DEBUG_INFO_ENABLED: boolean = true;

//TODO - this has already been moved to api.constants.ts
//after all services get rid of usage of SERVER_API_URL, delete the following from this file
export const APP_URL = `//${window.location.hostname}/`;
export const SERVER_API_URL = `//ccmis-api.dev.sandbox3000.com/`;
export const RESOURCE_URL_BASE = `//${window.location.hostname}/resources/`;
